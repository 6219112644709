import { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import useUserData from "./useUserData";
import { useOnlineUsers } from "../contexts/OnlineUserContext";

const useSocket = (onNewMessage) => {
  const { setOnlineUsers, chatHistory, setChatHistory, receiver, setReceiver } =
    useOnlineUsers();
  const [unreadMessages, setUnreadMessages] = useState({});
  const [unreadGroupMessages, setUnreadGroupMessages] = useState({});
  const [groupLastMessage, setGroupLastMessage] = useState({});
  const userData = useUserData();
  const socketRef = useRef(null);

  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = io(process.env.REACT_APP_BACKEND_URL);
    }
    const socket = socketRef.current;

    socket.on("connect", () => {
      if (userData) {
        socket.emit("join", userData._id);
      }
    });

    const handleBeforeUnload = () => {
      if (userData && socket) {
        socket.emit("user_offline", userData._id);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    socket.on("userStatusChange", ({ userId, status, lastActive }) => {
      if (receiver && receiver._id === userId) {
        setReceiver((prevReceiver) => ({
          ...prevReceiver,
          isOnline: status === "online" ? true : false,
          lastActive: status === "offline" ? lastActive : null,
        }));
      }
      setOnlineUsers((prevUsers) => {
        const updatedUsers = new Map(prevUsers);
        if (status === "online") {
          updatedUsers.set(userId, true);
        } else {
          updatedUsers.delete(userId);
        }
        return updatedUsers;
      });
    });

    socket.on("newMessage", (messageData) => {
      if (onNewMessage) {
        onNewMessage(messageData);
      }
      if (messageData.type === "group") {
        const { groupId, sender } = messageData;

        if (sender !== userData._id) {
          setUnreadGroupMessages((prevCounts) => ({
            ...prevCounts,
            [groupId]: {
              ...prevCounts[groupId],
              [sender]: (prevCounts[groupId]?.[sender] || 0) + 1,
            },
          }));
        }
      } else if (userData._id === messageData.receiver) {
        const receiverId = messageData.receiver;

        setUnreadMessages((prevCounts) => {
          const newCount = (prevCounts[receiverId] || 0) + 1;
          return {
            ...prevCounts,
            [receiverId]: newCount,
            [`lastMessage_${receiverId}`]: messageData.message,
          };
        });
      }
    });

    socket.on("unreadCount", ({ userId, unreadCount, lastMessage }) => {
      setUnreadMessages((prevCounts) => {
        if (prevCounts[userId] !== unreadCount) {
          return {
            ...prevCounts,
            [userId]: unreadCount,
            [`lastMessage_${userId}`]: lastMessage,
          };
        }
        return prevCounts;
      });
    });

    socket.on("groupLastMessage", ({ groupId, lastMessage }) => {
      setGroupLastMessage((prevLastMessages) => ({
        ...prevLastMessages,
        [groupId]: lastMessage,
      }));
    });

    socket.on("groupTyping", ({ userId, groupId, firstName, lastName }) => {
      if (onNewMessage) {
        onNewMessage({
          userId,
          groupId,
          firstName,
          lastName,
          typing: true,
          receiverType: "group",
        });
      }
    });

    socket.on("stopGroupTyping", ({ userId }) => {
      if (onNewMessage) {
        onNewMessage({ userId, typing: false });
      }
    });

    // Listen for message status updates (Delivered/Read)
    socket.on("messageStatusUpdate", ({ messageId, status }) => {
      setChatHistory((prevMessages) => {
        const updatedMessages = prevMessages
          .filter(
            (msg) =>
              msg.sender === userData._id || msg.receiver === userData._id
          )
          .map((msg) => (msg._id === messageId ? { ...msg, status } : msg));
        return updatedMessages;
      });
    });

    socket.on("groupMessageStatusUpdate", ({ updates }) => {
      if (updates) {
        setChatHistory((prevMessages) => {
          const statusUpdatesMap = updates?.reduce(
            (acc, { messageId, status }) => {
              acc[messageId] = status;
              return acc;
            },
            {}
          );

          const updatedMessages = prevMessages.map((msg) => {
            if (statusUpdatesMap[msg._id]) {
              return { ...msg, status: statusUpdatesMap[msg._id] };
            }
            return msg;
          });

          return updatedMessages;
        });
      }
    });

    socket.on("groupUnreadCount", ({ groupId, unreadCount, userId }) => {
      setUnreadGroupMessages((prevCounts) => {
        const groupUnread = prevCounts[groupId] || {};
        if (groupUnread[userId] !== unreadCount) {
          return {
            ...prevCounts,
            [groupId]: {
              ...groupUnread,
              [userId]: unreadCount,
            },
          };
        }
        return prevCounts;
      });
    });

    socket.on("typing", ({ senderId }) => {
      if (onNewMessage) {
        onNewMessage({ senderId, typing: true });
      }
    });

    socket.on("stopTyping", ({ senderId }) => {
      if (onNewMessage) {
        onNewMessage({ senderId, typing: false });
      }
    });

    return () => {
      if (socket) {
        socket.off("connect");
        socket.off("newMessage");
        socket.off("typing");
        socket.off("stopTyping");
        socket.off("groupTyping");
        socket.off("stopGroupTyping");
        socket.off("userStatusChange");
        socket.off("messageStatusUpdate");
        socket.off("groupUnreadCount");
        socket.off("groupLastMessage");
        socket.off("unreadCount");
        socket.off("disconnect");
      }
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [
    userData,
    setOnlineUsers,
    onNewMessage,
    setChatHistory,
    receiver,
    setReceiver,
    chatHistory,
    unreadGroupMessages,
  ]);

  const joinGroup = (groupId) => {
    if (socketRef.current && userData) {
      socketRef.current.emit("joinGroup", { groupId, userId: userData._id });
    }
  };

  const startTyping = (receiverId) => {
    if (socketRef.current && userData) {
      socketRef.current.emit("typing", {
        senderId: userData._id,
        receiverId,
      });
    }
  };

  const stopTyping = (receiverId) => {
    if (socketRef.current && userData) {
      socketRef.current.emit("stopTyping", {
        senderId: userData._id,
        receiverId,
      });
    }
  };

  const startGroupTyping = (groupId) => {
    if (socketRef.current && userData) {
      socketRef.current.emit("groupTyping", {
        groupId,
        userId: userData._id,
        firstName: userData.firstName,
        lastName: userData.lastName,
      });
    }
  };

  const stopGroupTyping = (groupId) => {
    if (socketRef.current && userData) {
      socketRef.current.emit("stopGroupTyping", {
        groupId,
        userId: userData._id,
      });
    }
  };

  const joinChatRoom = (receiverId) => {
    if (socketRef.current && userData) {
      socketRef.current.emit("joinChatRoom", {
        senderId: userData._id,
        receiverId,
      });
    }
  };

  const sendGroupMessage = (groupId, message) => {
    if (socketRef.current) {
      socketRef.current.emit("newMessage", { groupId, message });
    }
  };

  const sendMessage = (receiverId, message) => {
    if (socketRef.current) {
      socketRef.current.emit("newMessage", { receiverId, message });
    }
  };

  const markAsRead = (messageIdsToMarkRead, receiverId) => {
    if (socketRef.current) {
      socketRef.current.emit("messagesRead", {
        messageIds: messageIdsToMarkRead,
        receiverId,
      });
    }
  };

  const markGroupMessagesAsRead = (groupId, messageIds) => {
    if (socketRef.current && userData) {
      socketRef.current.emit("groupMessagesRead", {
        groupId,
        userId: userData._id,
        messageIds,
      });
    }
  };

  return {
    socket: socketRef.current,
    joinGroup,
    sendMessage,
    joinChatRoom,
    startTyping,
    stopTyping,
    markAsRead,
    unreadMessages,
    setUnreadMessages,
    unreadGroupMessages,
    setUnreadGroupMessages,
    sendGroupMessage,
    groupLastMessage,
    markGroupMessagesAsRead,
    setGroupLastMessage,
    startGroupTyping,
    stopGroupTyping,
  };
};

export default useSocket;

import { handleApiRequest } from "../../utils/handleApiRequest";

export const sendRequest = (formData) =>
    handleApiRequest("POST", "/send-request", formData);

export const responseRequest = (formData) =>
    handleApiRequest("POST", "/respond-request", formData);

export const connectedUser = () => handleApiRequest("GET", "/all-connections");

export const allNotifications = () => handleApiRequest("GET", "/all-notifications")

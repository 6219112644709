import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import "cropperjs/dist/cropper.css";
import { Button, DialogTitle } from "@headlessui/react";
import SelectList from "../../components/common/SelectList";
import { createGroup } from "../../services/apis/ChatApis";
import { connectedUser } from "../../services/apis/connectionApis";
import { MdOutlineCancel } from "react-icons/md";
import { useModal } from "../../contexts/ModelContext";
import CommonDialog from "../../components/common/CommonDialog";
import { AiOutlineClose } from "react-icons/ai";
import { createGroupValidation } from "../../utils/validationSchemas";
import TextInput from "../../components/common/TextInput";
import { FaGreaterThan } from "react-icons/fa6";
import { HiUserGroup } from "react-icons/hi2";

function CreateGroupModal({ open, setOpen, onClose }) {
  const { showModal } = useModal();
  const [students, setStudents] = useState([]);

  const fetchConnections = async () => {
    try {
      const response = await connectedUser();
      if (response.success) {
        const connections = Array.isArray(response.data) ? response.data : [];
        setStudents(connections);
      } else {
        console.error("Failed to fetch connections:", response.message);
        setStudents([]);
      }
    } catch (error) {
      console.error("Error fetching connections:", error);
      setStudents([]);
    }
  };

  useEffect(() => {
    if (open) {
      fetchConnections();
    }
  }, [open]);

  const studentOptions = students.map((connection, index) => ({
    value: connection._id,
    name: `${connection.firstName} ${connection.lastName}`,
    id: index,
  }));

  const handleAddStudent = (
    selectedOption,
    values,
    setFieldValue,
    setFieldTouched
  ) => {
    const selectedStudent = students.find(
      (connection) => connection._id === selectedOption
    );

    if (selectedStudent) {
      const newStudents = [...values.selectedStudents, selectedStudent];
      setFieldValue("selectedStudents", newStudents);
      setFieldValue("students", "");
      setFieldTouched("selectedStudents", false);
    }
  };

  const handleRemoveStudent = (
    student,
    values,
    setFieldValue,
    setFieldTouched
  ) => {
    const updatedStudents = values.selectedStudents.filter(
      (item) => item._id !== student._id
    );
    setFieldValue("selectedStudents", updatedStudents);
    setFieldTouched("selectedStudents", true);
  };

  const handleCreateGroup = async (values, { resetForm }) => {
    try {
      const formData = new FormData();
      formData.append("groupName", values.groupName);
      formData.append(
        "studentIds",
        JSON.stringify(values.selectedStudents.map((student) => student._id))
      );
      formData.append("groupImage", values.groupImage);
      const response = await createGroup(formData);
      showModal(response);
      if (response.success) {
        resetForm();
        onClose();
      }
    } catch (error) {
      console.error("Error creating group:", error);
    }
  };
  // const [image, setImage] = useState(null);

  const handleImageChange = (e, setFieldValue) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      // reader.onloadend = () => {
      //   setImage(reader.result);
      // };
      reader.onloadend = () => {
        setFieldValue("groupImage", file); // Update Formik state
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <CommonDialog open={open} setOpen={setOpen}>
      <div className="bg-white p-[24px] rounded-lg">
        <DialogTitle
          as="h3"
          className="text-[24px] font-bold text-primary  text-center"
        >
          Create Group
        </DialogTitle>
        <button
          type="button"
          onClick={onClose}
          className="absolute right-4 top-4 p-1 hover:bg-gray-100 rounded-full transition-colors"
        >
          <AiOutlineClose className="text-2xl" />
        </button>
        <div className="">
          <Formik
            initialValues={{
              students: "",
              groupName: "",
              groupImage: "",
              selectedStudents: [],
            }}
            validationSchema={createGroupValidation}
            onSubmit={handleCreateGroup}
          >
            {({
              values,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
              errors,
              touched,
            }) => (
              <Form>
                <div className="mb-4">
            
                  <label className="w-full text-[16px] text-black">
                    Group Image
                    <span className="text-red text-[18px] pl-1">*</span>
                  </label>
                  {/* <div className="flex justify-start mt-3 max-w-[170px] w-full relative">
                    <label
                      htmlFor="file-upload"
                      className={`relative cursor-pointer w-[170px] h-[170px] object-cover shadow-[0px_0px_4px_00000042] rounded-[10px] overflow-hidden border-2 ${
                        errors.groupImage ? "border-red-500" : "border-gray-300"
                      } transition`}
                    >
                      <img
                        src={
                          values.groupImage
                            ? URL.createObjectURL(values.groupImage)
                            : defualtImage
                        }
                        alt="group-profile"
                        className="w-[170px] h-[170px] rounded-[10px] object-cover"
                      />
                      <input
                        id="file-upload"
                        type="file"
                        accept="image/*"
                        className="hidden"
                        onChange={(e) => {
                          setFieldValue("groupImage", e.target.files[0]);
                          setFieldTouched("groupImage", false);
                        }}
                      />
                    </label>
                  </div> */}

                  <label
                    htmlFor="image-upload"
                    className="border-[2px] mt-2 border-primary rounded-[8px] flex flex-col justify-center items-center border-dashed max-w-[170px] h-[170px] w-full cursor-pointer"
                  >
                    {!values.groupImage ? (
                      <>
                        <HiUserGroup className="text-3xl text-gray-600" />
                        <p className="text-lg text-gray-600">Upload Image</p>
                      </>
                    ) : (
                      <img
                        // src={image}
                        src={URL.createObjectURL(values.groupImage)}
                        alt="Uploaded Preview"
                        className="w-[170px] h-[170px] object-cover rounded-md"
                      />
                    )}
                    <input
                      type="file"
                      // onChange={handleImageChange}
                      onChange={(e) => handleImageChange(e, setFieldValue)}
                      className="hidden"
                      id="image-upload"
                    />
                  </label>
                  {touched.groupImage && errors.groupImage && (
                    <p className="text-red text-sm mt-1">{errors.groupImage}</p>
                  )}
                </div>

                <div className="mb-4">
                  <TextInput
                    label="Group Name"
                    name="groupName"
                    placeholder="Enter Group Name"
                    isRequired={true}
                  />
                </div>

                <div className="mb-4 ">
                  <SelectList
                    label="Select Students"
                    options={studentOptions}
                    value={values.students}
                    onChange={(e) =>
                      handleAddStudent(
                        e,
                        values,
                        setFieldValue,
                        setFieldTouched
                      )
                    }
                    name="selectedStudents"
                    placeholder="Select students"
                    isRequired={true}
                    className="h-[100px] overflow-auto my-scroll"
                  />
                  {touched.selectedStudents &&
                    errors.selectedStudents &&
                    values.selectedStudents.length === 0 && (
                      <p className="text-red text-sm mt-1">
                        {errors.selectedStudents}
                      </p>
                    )}
                  {values.selectedStudents &&
                    values.selectedStudents.length > 0 && (
                      <div className="mt-4 flex gap-3 items-center flex-wrap">
                        {values.selectedStudents.map((student, index) => (
                          <div key={student._id} className="flex items-center">
                            <button
                              type="button"
                              className="text-nevyblue px-[10px] text-[16px] font-medium flex justify-center gap-[10px] items-center border-[1.5px] border-[#273446] rounded-[6px] h-9 capitalize"
                            >
                              {`${student.firstName} ${student.lastName}`}
                              <MdOutlineCancel
                                className="text-red w-4 h-4 cursor-pointer"
                                onClick={() =>
                                  handleRemoveStudent(
                                    student,
                                    values,
                                    setFieldValue,
                                    setFieldTouched
                                  )
                                }
                              />
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                </div>

                <div className="flex justify-end gap-4 mt-4">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    className={`px-4 py-3 text-base text-white bg-primary rounded flex justify-center items-center gap-3 `}
                  >
                    Create Group
                    <FaGreaterThan />
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </CommonDialog>
  );
}

export default CreateGroupModal;

import * as Yup from 'yup';

export const classValidationSchema = Yup.object().shape({
    title: Yup.string()
        .required('Title is required')
        .min(3, 'Title must be at least 3 characters long'),
    // selectedAttendees: Yup.array()
    //     .of(Yup.string())
    //     .nullable(),
    // attendees: Yup.string().required("Attendees are required"),
    session: Yup.string()
        .required('Session is required')
        .min(3, 'Session must be at least 3 characters long'),
    classType: Yup.string()
        .required('Class type is required'),
    subcategory: Yup.string()
        .required('Subcategory is required'),
    skillLevel: Yup.string()
        .required('Skill level is required'),
    startTime: Yup.date()
        .required('Start time is required'),
    endTime: Yup.date()
        .required('End time is required')
        .min(
            Yup.ref('startTime'),
            'End time cannot be earlier than start time'
        )
        .test(
            'not-same-time',
            'Start time and end time cannot be the same',
            function (value) {
                const { startTime } = this.parent;
                return value && startTime && value.getTime() !== startTime.getTime();
            }
        ),
    frequency: Yup.string().required("Frequency is required"),
    dayOfWeek: Yup.string().when("frequency", {
        is: "custom",
        then: Yup.string()
            .required("Day of week is required for custom frequency"),
    }),
    selectedLanguages: Yup.array()
        .of(Yup.string())
        .nullable(),
    selectedDaysOfWeek: Yup.array().of(Yup.string()).nullable(),
    description: Yup.string()
        .required("Description is required")
        .min(50, "Description must be at least 50 characters")
        .max(500, "Description cannot exceed 500 characters"),
    startDate: Yup.date()
        .required("Start date is required")
        .typeError("Invalid start date"),
    endDate: Yup.date()
        .required("End date is required")
        .typeError("Invalid end date")
        .min(
            Yup.ref("startDate"),
            "End date must be later than or equal to the start date"
        ),
});

export const classMediaValidation = Yup.object().shape({
    thumbnail: Yup.mixed()
        // .nullable()
        // .notRequired()
        .required("Thumbnail is required.")
        .test(
            "fileType",
            "Unsupported file format. Only .jpg, .jpeg, .png are allowed.",
            (value) =>
                !value ||
                typeof value === "string" ||
                (value && ["image/jpeg", "image/png", "image/jpg"].includes(value.type))
        )
        .test(
            "fileSize",
            "File size is too large. Maximum size is 1 MB.",
            (value) =>
                !value ||
                typeof value === "string" ||
                (value && value.size <= 1 * 1024 * 1024)
        ),
    promoVideo: Yup.mixed()
        .nullable()
        .notRequired()
        .test(
            "fileType",
            "Unsupported file format. Only video formats are allowed.",
            (value) =>
                !value ||
                typeof value === "string" ||
                (value &&
                    ["video/mp4", "video/m4v", "video/x-m4v", "video/avi", "video/mov"].includes(value.type))
        )
        .test(
            "fileSize",
            "File size is too large. Maximum size is 1 MB.",
            (value) =>
                !value ||
                typeof value === "string" ||
                (value && value.size <= 1 * 1024 * 1024)
        ),
    attachment: Yup.mixed()
        .nullable()
        .notRequired()
        .test(
            "fileType",
            "Unsupported file format. Only .pdf, .doc, .docx, .ppt, .pptx, .txt, .rtf, .xls, .xlsx are allowed.",
            (value) =>
                !value || // Allow empty values
                typeof value === "string" || // Skip validation if it's a string
                (typeof value === "object" && value.url) || // Skip validation if it has a valid object with a URL
                (value &&
                    value.type &&
                    [
                        "application/pdf",
                        "application/msword",
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        "application/vnd.ms-powerpoint",
                        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                        "text/plain",
                        "application/rtf",
                        "application/vnd.ms-excel",
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    ].includes(value.type))
        )
        .test(
            "fileSize",
            "File size is too large. Maximum size is 1 MB.",
            (value) =>
                !value || // Allow empty values
                typeof value === "string" || // Skip validation if it's a string
                (typeof value === "object" && value.url) || // Skip validation if it has a valid object with a URL
                (value && value.size <= 1 * 1024 * 1024) // Enforce size validation only for uploaded files
        )
        .test(
            "validObject",
            "Invalid attachment object. Object must contain a valid 'name'.",
            (value) =>
                !value || // Allow empty values
                typeof value === "string" || // Skip validation if it's a string
                (typeof value === "object" &&
                    value.name && // Ensure the object has a valid `name`
                    typeof value.name === "string")
        ),
    teaching: Yup.array()
        .nullable()
        .notRequired()
        .of(
            Yup.string()
                .required("This field is required.")
                .min(5, "Must be at least 5 characters.")
                .max(100, "Must be less than 100 characters.")
        ),
});

export const teacherProfileValidation = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
        .required("Email is required")
        .min(3, "Email must be at least 3 characters long"),
    contactNumber: Yup.string().required("Contact Number is required"),
    source: Yup.string().required("Source is required"),
    // subscription: Yup.string().required("Subscription is required"),
    availability: Yup.string().required("Availability is required"),
    startTime: Yup.string().required("Start time is required"),
    endTime: Yup.string().required("End time is required"),
    duration: Yup.string().required("Duration is required"),
});

export const studentProfileValidation = Yup.object().shape({
    firstName: Yup.string()
        .required("First name is required")
        .max(50, "First name must be at most 50 characters"),
    lastName: Yup.string()
        .required("Last name is required")
        .max(50, "Last name must be at most 50 characters"),
    source: Yup.string().required("Source is required"),
    contactNumber: Yup.string()
        .required("Contact number is required")
        .matches(/^[0-9]+$/, "Contact number must be numeric")
        .min(10, "Contact number must be at least 10 digits")
        .max(15, "Contact number must be at most 15 digits"),
    areaOfInterest: Yup.array()
        .of(Yup.string().required("Interest is required"))
        .min(1, "At least one area of interest is required"),
    availability: Yup.string().required("Availability is required"),
    startTime: Yup.string().required("Start time is required"),
    endTime: Yup.string().required("End time is required"),
    duration: Yup.string().required("Duration is required"),

})

export const skillMeticsValidation = Yup.object().shape({
    skillMatrix: Yup.array().of(
        Yup.object().shape({
            category: Yup.string().required("Category is required"),
            subcategory: Yup.string().required("Subcategory is required"),
            skillLevel: Yup.string().required("Skill Level is required"),
        })
    ),
});

export const planValidation = Yup.object().shape({
    subscription: Yup.string().required("Subsciption is required"),
    plan: Yup.string().required("PlanDuration is required ."),

})

export const signupValidation = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    role: Yup.string().required("Role is required"),
    contactNumber: Yup.string()
        .required("Contact number is required")
        .matches(/^[0-9]+$/, "Contact number must be numeric")
        .min(10, "Contact number must be at least 10 digits")
        .max(15, "Contact number must be at most 15 digits"),
    password: Yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(
            /[_@!#$]/,
            "Password must contain at least one special character (e.g., @, _, !, #, $, etc.)"
        ),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
    agreeTerms: Yup.bool().oneOf([true], "You must agree to the Terms and Conditions"),
});

export const changePasswordValidation = Yup.object().shape({
    currentPassword: Yup.string()
        .required("Current password is required")
        .min(8, "Password must be at least 8 characters"),
    newPassword: Yup.string()
        .required("New password is required")
        .min(8, "Password must be at least 8 characters")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(
            /[_@!#$]/,
            "Password must contain at least one special character (e.g., @, _, !, #, $, etc.)")
        .notOneOf(
            [Yup.ref("currentPassword"), null],
            "New password must be different from the current password"
        ),
    confirmPassword: Yup.string()
        .required("Confirm password is required")
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
});

export const setPasswordValidation = Yup.object().shape({
    newPassword: Yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(
            /[_@!#$]/,
            "Password must contain at least one special character (e.g., @, _, !, #, $, etc.)"
        ),
    confirmPassword: Yup.string()
        .required("Confirm password is required")
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
});

export const editClassValidation = Yup.object().shape({
    startDate: Yup.date()
        .required("Start date is required")
        .typeError("Invalid start date"),
    endDate: Yup.date()
        .required("End date is required")
        .typeError("Invalid end date")
        .min(
            Yup.ref("startDate"),
            "End date must be later than or equal to the start date"
        ),
    startTime: Yup.date()
        .required('Start time is required'),
    endTime: Yup.date()
        .required('End time is required')
        .min(
            Yup.ref('startTime'),
            'End time cannot be earlier than start time'
        ),
});

export const createGroupValidation = Yup.object().shape({
    groupName: Yup.string()
        .required("Group name is required")
        .min(3, "Group name must be at least 3 characters"),
    groupImage: Yup.mixed()
        .required("Group image is required")
        .test(
            "fileType",
            "Only image files are allowed",
            (value) =>
                !value || (value && ["image/jpeg", "image/png", "image/jpg"].includes(value.type))
        ),
    selectedStudents: Yup.array()
        .of(
            Yup.object({
                _id: Yup.string().required(),
                firstName: Yup.string().required(),
                lastName: Yup.string().required(),
            })
        )
        .min(1, "You must select at least one student")
})
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import teacherProfileReducer from './teacherProfileSlice';
import studentProfileReducer from './studentProfileSlice';
import categoryReducer from "./categorySlice"
import scheduleReducer from "./scheduleSlice"
import teacherListReducer from './teacherListSlice';
import classesReducer from './classListSlice'
import filtersReducer from './filterSlice'

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  teacherProfile: teacherProfileReducer,
  studentProfile: studentProfileReducer,
  categories: categoryReducer,
  schedules: scheduleReducer,
  teachers: teacherListReducer,
  classes: classesReducer,
  filters: filtersReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
export default store;

import { createSlice } from '@reduxjs/toolkit';

const filtersSlice = createSlice({
    name: 'filters',
    initialState: {
        skillLevel: [],
        subcategory: [],
        customFilters: ''
    },
    reducers: {
        setSkillLevels: (state, action) => {
            state.skillLevel = action.payload;
        },
        setSubcategories: (state, action) => {
            state.subcategory = action.payload;
        },
        setCustomFilters: (state, action) => {
            state.customFilters = action.payload;
        },
        resetFilters: (state) => {
            state.skillLevel = [];
            state.subcategory = [];
            state.customFilters = '';
        }
    }
});

export const {
    setSkillLevels,
    setSubcategories,
    setCustomFilters,
    resetFilters
} = filtersSlice.actions;
export default filtersSlice.reducer;

// import { onMessage } from 'firebase/messaging';
import Router from './pages/router/Router';
// import { messaging } from './firebase/firebase';

function App() {
  // onMessage(messaging, (payload) => {
  //   alert(payload.notification.title, payload.notification.body);
  // });
  return (
    <div>
      <Router />
    </div>
  );
}

export default App;




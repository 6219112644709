import { DialogTitle } from "@headlessui/react";
import CHECK from "../assets/Images/check.png";
import ERROR from "../assets/Images/Error.webp";
import React from "react";
import Button from "../components/common/Button";
import CommonDialog from "../components/common/CommonDialog";

function MessageModal({
  open,
  setOpen,
  title = "All Set!",
  message,
  isSuccess = true,
  buttonText = "Done",
}) {
  return (
    <CommonDialog open={open} setOpen={setOpen}>
      <div className="bg-white sm:p-[40px] p-[24px]">
        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <div className="flex justify-center">
            <img
              src={isSuccess ? CHECK : ERROR}
              alt="Status icon"
              className="w-full max-w-[75px]"
            />
          </div>
          <DialogTitle
            as="h3"
            className={`text-[24px] font-bold text-primary mb-[32px] mt-[25px] text-center`}
          >
            {title}
          </DialogTitle>
          <div className="mt-2">
            <p className="sm:text-[24px] text-[18px] font-normal text-gray-500 mb-[52px] text-center">
              {message}
            </p>
          </div>
          <div className="flex justify-center">
            <Button
              onClick={() => setOpen(false)}
              text={buttonText}
              className={`text-[20px] font-bold text-white max-w-[174px] w-full h-[45px] bg-primary rounded-lg`}
            />
          </div>
        </div>
      </div>
    </CommonDialog>
  );
}

export default MessageModal;

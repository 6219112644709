import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchClasses = createAsyncThunk(
  "classes/fetchClasses",
  async (
    {
      skillLevel = [],
      subcategory = [],
      customFilters = "",
      minPrice = "",
      maxPrice = "",
      minRating = "",
      page = 1,
      limit = 10,
    },
    { rejectWithValue }
  ) => {
    try {
      const params = {
        skillLevel: skillLevel.length > 0 ? skillLevel.join(",") : undefined,
        subcategory: subcategory.length > 0 ? subcategory.join(",") : undefined,
        customFilters: customFilters,
        minPrice: minPrice,
        maxPrice: maxPrice,
        minRating: minRating,
        page,
        limit,
      };

      Object.keys(params).forEach(
        (key) => params[key] === undefined && delete params[key]
      );

      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/api/get-all-class",
        { params }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const classesSlice = createSlice({
  name: "classes",
  initialState: {
    classes: [],
    totalCount: 0,
    totalPages: 0,
    currentPage: 1,
    status: "idle",
    error: null,
    loading: true,
  },
  reducers: {
    resetClasses: (state) => {
      state.classes = [];
      state.totalCount = 0;
      state.totalPages = 0;
      state.currentPage = 1;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClasses.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(fetchClasses.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        const { classes, totalCount, totalPages, currentPage } = action.payload;
        state.classes = classes || [];
        state.totalCount = totalCount || 0;
        state.totalPages = totalPages || 0;
        state.currentPage = currentPage || 1;
      })
      .addCase(fetchClasses.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.payload || "An error occurred";
      });
  },
});

export const { resetClasses } = classesSlice.actions;

export default classesSlice.reducer;
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import store, { persistor } from './redux/store';
import { ModalProvider } from './contexts/ModelContext';
import { StyledEngineProvider } from '@mui/material/styles';
// import { getMessaging, getToken } from 'firebase/messaging';
// import { app } from './firebase/firebase';
import { SearchProvider } from './contexts/SearchContext';
import { OnlineUserProvider } from './contexts/OnlineUserContext';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

// const messaging = getMessaging(app);

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('/firebase-messaging-sw.js')
//     .then((registration) => {
//       console.log('Service Worker registered with scope: ', registration.scope);
//     })
//     .catch((error) => {
//       console.error('Service Worker registration failed: ', error);
//     });
// }

// const requestNotificationPermission = async () => {
//   try {
//     const permission = await Notification.requestPermission();
//     if (permission !== 'granted') {
//       console.error('Permission for notifications was denied');
//       return;
//     }

//     // Get FCM token
//     const token = await getToken(messaging, {
//       vapidKey: process.env.REACT_APP_VAPIKEY,
//     });
//     if (token) {
//       console.log('FCM Token:', token);
//     } else {
//       console.log('No token available');
//     }
//   } catch (error) {
//     console.error('Error getting FCM token:', error);
//   }
// };

// requestNotificationPermission();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <OnlineUserProvider>
        <SearchProvider>
          <StyledEngineProvider injectFirst>
            <PersistGate loading={null} persistor={persistor}>
              <ModalProvider>
                <BrowserRouter>
                  <Elements stripe={stripePromise}>
                    <App />
                  </Elements>
                </BrowserRouter>
              </ModalProvider>
            </PersistGate>
          </StyledEngineProvider>
        </SearchProvider>
      </OnlineUserProvider>
    </Provider>
  </React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


// <Elements stripe={stripePromise}></Elements>
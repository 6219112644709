import React from "react";

const ChatLodar = () => {
  return (
    <>
      {[...Array(8)].map((_, index) => (
        <div
          key={index}
          className={`flex ${
            index % 2 === 0
              ? "justify-end items-end"
              : "justify-start items-start"
          }`}
        >
          <div className="animate-pulse flex items-end pr-4 pt-4">
            <div
              className={`ml-4 space-y-2 flex ${
                index % 2 === 0
                  ? "justify-end items-end"
                  : "justify-start items-start"
              } flex-col`}
            >
              <div className="h-7 bg-[#ccc] rounded w-[200px]"></div>
              <div className="h-7 bg-[#ccc] rounded w-[400px]"></div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ChatLodar;

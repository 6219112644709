import React, { useEffect, useState } from "react";
import LOGO from "../../assets/Images/Logo.png";
import { NavLink } from "react-router-dom";
import { getUserRole } from "../../pages/router/ProtectedRoute";
import { getProfile } from "../../services/apis/authentication";
import { menus } from "../../constant/dataConstant";
import { useSelector } from "react-redux";

function Sidebar() {
  const role = getUserRole();
  const [isSetProfile, setIsSetProfile] = useState(false);
  const [isCredibility, setIsCredibilty] = useState(false);
  const teacherProfile = useSelector((state) => state.teacherProfile);

  const getProfileFormData = async () => {
    try {
      const data = await getProfile();
      const profileData = data.user;
      setIsSetProfile(profileData.profileSetup);
      setIsCredibilty(profileData?.isCredible);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getProfileFormData();
  }, [teacherProfile]);

  return (
    <>
      {/* Sidebar */}
      <aside className="h-screen bg-white fixed left-0 top-0 p-[14px_24px] whitespace-nowrap z-10 closed shadow-[0px_0px_10px_-2px_#0000003f] w-[300px] hidden xl:block">
        <div className="mb-10 flex items-center justify-between ">
          <img src={LOGO} alt="" className="max-w-[92px] w-full" />
          <button className="lg:hidden bg-gray-200 text-gray-500 rounded leading-none p-1 btn-close-menu">
            <i data-feather="chevron-left"></i>
          </button>
        </div>

        <ul className="text-gray-500 font-semibold flex flex-col gap-2">
          {menus
            .filter(
              (menu) => !menu.roleRestriction || menu.roleRestriction(role)
            )
            .map((menu, index) => {
              const isDisabled =
                menu.disabledCondition &&
                menu.disabledCondition({
                  profileSetup: isSetProfile,
                  isCredible: isCredibility,
                });

              return (
                <NavLink
                  to={isDisabled ? null : menu.link(role)}
                  key={index}
                  className={({ isActive }) =>
                    isDisabled
                      ? "bg-blue-100 cursor-not-allowed opacity-50 rounded-[8px]"
                      : menu.link(role) === "#"
                      ? ""
                      : isActive
                      ? "bg-primary active-link text-white rounded-[8px]"
                      : ""
                  }
                  onClick={(e) => {
                    if (isDisabled) {
                      e.preventDefault();
                    }
                  }}
                >
                  <li className="rounded-[8px] p-[17px] group hover:bg-primary transition-all">
                    <div className="flex items-center gap-[15px] hover:text-white">
                      <menu.icon
                        className={`group-hover:text-white text-[22px] ${
                          isDisabled && "text-gray-700"
                        }`}
                      />
                      <h1
                        className={`mb-0 text-nevyblue text-[16px] font-bold leading-[2%] group-hover:text-white ${
                          isDisabled && "text-gray-700"
                        }`}
                      >
                        {menu.label === "Find Teacher / My Students"
                          ? role === "student"
                            ? "Find Teacher"
                            : "My Students"
                          : menu.label}
                      </h1>
                    </div>
                  </li>
                </NavLink>
              );
            })}
        </ul>
      </aside>
    </>
  );
}

export default Sidebar;

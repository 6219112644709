import { createSlice } from "@reduxjs/toolkit";

const initialCategoryState = {
    categories: [],
    subcategories: [],
};

const categorySlice = createSlice({
    name: "categories",
    initialState: initialCategoryState,
    reducers: {
        updateCategories: (state, action) => {
            state.categories = action.payload;
        },
        updateSubcategories: (state, action) => {
            state.subcategories = action.payload;
        },
        clearCategories: () => initialCategoryState,
    },
});

export const { updateCategories, updateSubcategories, clearCategories } =
    categorySlice.actions;

export default categorySlice.reducer;

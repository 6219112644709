import React, { useCallback, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import CommonLayout from "../../components/common/CommonLayout";
import useSocket from "../../hooks/useSocket";
import useUserData from "../../hooks/useUserData";
import {
  getChatHistory,
  getChatUserList,
  sendChat,
} from "../../services/apis/ChatApis";
import { IoSend } from "react-icons/io5";
import { useOnlineUsers } from "../../contexts/OnlineUserContext";
import { formattedLastActive } from "../../utils/utils";
import { MdKeyboardArrowLeft } from "react-icons/md";
import ChatUserLoader from "../../components/skeleton/ChatUserLoader";
import { PiChatsBold } from "react-icons/pi";
import CreateGroupModal from "../../model/chat/CreateGroupModal";
import { BiPlus } from "react-icons/bi";
import { debounce } from "lodash";
import ChatLodar from "../../components/skeleton/ChatLodar";
import MessageLoder from "../../components/skeleton/MessageLoder";
// import defaultImage from "../../assets/Images/user.png";

dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

function Chat() {
  const { onlineUsers, chatHistory, setChatHistory, receiver, setReceiver } =
    useOnlineUsers();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isChatWindowVisible, setIsChatWindowVisible] = useState(false);
  const [groupTypingUser, setGroupTypingUser] = useState(null);
  const [loadUser, setLoadUser] = useState(true);
  const userData = useUserData();
  const [typingUser, setTypingUser] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loadChat, setLoadChat] = useState(true);
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState("");
  const containerRef = useRef(null);

  const onNewMessage = (messageData) => {
    if (messageData.typing) {
      if (receiver._id === messageData.senderId) {
        setTypingUser(messageData);
      } else if (receiver._id === messageData.groupId) {
        setGroupTypingUser(messageData);
      }
    } else if (messageData.typing === false) {
      setTypingUser(null);
      setGroupTypingUser(null);
    } else {
      setChatHistory((prevMessages) => [...prevMessages, messageData]);
    }
  };

  const {
    joinChatRoom,
    joinGroup,
    sendMessage,
    startTyping,
    stopTyping,
    unreadMessages,
    unreadGroupMessages,
    markAsRead,
    setUnreadMessages,
    setUnreadGroupMessages,
    sendGroupMessage,
    groupLastMessage,
    markGroupMessagesAsRead,
    setGroupLastMessage,
    startGroupTyping,
    stopGroupTyping,
  } = useSocket(onNewMessage);

  const handleMarkAsRead = useCallback(
    debounce((messageIds) => {
      if (messageIds.length > 0) {
        markAsRead(messageIds, userData._id);
      }
    }, 500),
    [markAsRead, userData._id]
  );

  const handleMarkGroupMessagesAsRead = useCallback(
    debounce((groupId, messageIds) => {
      if (groupId && messageIds.length > 0) {
        markGroupMessagesAsRead(groupId, messageIds);
      }
    }, 500),
    [markGroupMessagesAsRead]
  );

  useEffect(() => {
    if (chatHistory.length !== 0 && receiver) {
      const isGroupChat = receiver.type === "group";

      if (!isGroupChat) {
        const messagesToMarkAsRead = chatHistory
          .filter(
            (msg) =>
              msg.status !== "read" &&
              msg.receiver === userData._id &&
              msg.sender === receiver._id
          )
          .map((msg) => msg._id);

        if (messagesToMarkAsRead.length > 0) {
          handleMarkAsRead(messagesToMarkAsRead);
        }
      } else {
        const readGroupMessage = chatHistory
          .filter(
            (msg) => msg.groupId === receiver._id && msg.status !== "read"
          )
          .map((msg) => msg._id);

        if (readGroupMessage.length > 0) {
          handleMarkGroupMessagesAsRead(receiver._id, readGroupMessage);
        }
      }
    }
  }, [
    chatHistory,
    receiver,
    userData._id,
    handleMarkAsRead,
    handleMarkGroupMessagesAsRead,
  ]);

  const isUserOnline = (userId) => onlineUsers.has(userId);

  const handleTyping = (e) => {
    setMessage(e.target.value);

    if (e.target.value) {
      if (receiver.type === "group") {
        startGroupTyping(receiver._id);
      } else if (receiver.type === "user") {
        startTyping(receiver._id);
      }
    } else {
      if (receiver.type === "group") {
        stopGroupTyping(receiver._id);
      } else if (receiver.type === "user") {
        stopTyping(receiver._id);
      }
    }
  };

  const handleSendMessage = async () => {
    if (message.trim() && receiver) {
      try {
        if (receiver.type === "group") {
          await sendChat({ groupId: receiver._id, message });
          sendGroupMessage(receiver._id, message);
          setMessage("");
        } else {
          await sendChat({ receiverId: receiver._id, message });
          sendMessage(receiver._id, message);
          setMessage("");
        }
        setMessage("");
        if (receiver.type === "group") {
          stopGroupTyping(receiver._id);
        } else {
          stopTyping(receiver._id);
        }

        if (containerRef.current) {
          containerRef.current.scrollTo({
            top: containerRef.current.scrollHeight,
            behavior: "smooth",
          });
        }
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleInputResize = (e) => {
    e.target.style.height = "45px";
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  useEffect(() => {
    if (receiver) {
      setPage(1);
      setTotalPages(0);
      loadMessages(1);
    }
  }, [receiver]);

  const isGroupChat = receiver?.type === "group";

  const filteredMessages = chatHistory?.filter((msg) => {
    if (isGroupChat) {
      return msg.groupId === receiver._id;
    } else {
      return (
        (msg.sender === userData._id && msg.receiver === receiver._id) ||
        (msg.sender === receiver._id && msg.receiver === userData._id)
      );
    }
  });

  const loadMessages = async (pageNumber = 1) => {
    // if (isLoading || (totalPages && pageNumber > totalPages)) {
    //   return;
    // }
    // setIsLoading(true);
    if (loadChat || (totalPages && pageNumber > totalPages)) return;
    setLoadChat(true);
    try {
      const data = await getChatHistory(receiver, pageNumber);
      if (data.success) {
        setChatHistory((prevMessages) => [...data.data, ...prevMessages]);
        setPage((prevPage) => prevPage + 1);
        setTotalPages(data.pagination.totalPages);
      } else {
        console.error("Error fetching chat history:", data.message);
      }
    } catch (error) {
      console.error("Error loading messages:", error);
    } finally {
      setIsLoading(false);
      setLoadChat(false);
    }
  };

  useEffect(() => {
    if (page === 1 && containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [chatHistory]);

  // const handleScroll = () => {
  //   if (containerRef.current.scrollTop === 0 && !isLoading) {
  //     loadMessages(page);
  //   }
  // };
  const handleScroll = () => {
    if (containerRef.current.scrollTop === 0) {
      loadMessages(page);
    }
  };
  const fetchUserList = async () => {
    try {
      const data = await getChatUserList();
      const UnreadMessages = {};
      const unreadGroupMessages = {};
      const groupLastMessages = {};

      data.users.forEach(({ type, _id, unreadCount, lastMessage, members }) => {
        if (type === "user") {
          UnreadMessages[_id] = unreadCount || 0;
          UnreadMessages[`lastMessage_${_id}`] = lastMessage;
        } else if (type === "group") {
          const memberUnreadCounts = {};

          members.forEach((member) => {
            memberUnreadCounts[member._id] = member.unreadCount || 0;
          });

          unreadGroupMessages[_id] = memberUnreadCounts;
          groupLastMessages[_id] = lastMessage;
        }
      });
      setUsers(data.users);
      setUnreadMessages(UnreadMessages);
      setUnreadGroupMessages(unreadGroupMessages);
      setGroupLastMessage(groupLastMessages);
    } catch (error) {
      console.error("Error fetching user list:", error);
    } finally {
      setLoadUser(false);
    }
  };

  const handleUserClick = (user) => {
    if (!receiver || receiver._id !== user._id) {
      setChatHistory([]);
      setMessage("");
      setLoadChat(true);
      setReceiver(user);
      try {
        if (user.type === "group") {
          setUnreadGroupMessages((prevCounts) => ({
            ...prevCounts,
            [`[user._id][userData._id]`]: 0,
          }));
        } else {
          setUnreadMessages((prevCounts) => ({
            ...prevCounts,
            [user._id]: 0,
            [`lastMessage_${user._id}`]: user.lastMessage || "",
          }));
        }
      } catch (error) {
        console.error("Error while Fetch Data:", error);
      } finally {
        setLoadChat(false);
      }
      // Scroll to bottom after selecting user
      setTimeout(() => {
        if (containerRef.current) {
          containerRef.current.scrollTo({
            top: containerRef.current.scrollHeight,
            behavior: "smooth",
          });
        }
      }, 300);
    }
    setIsChatWindowVisible(true);
  };

  useEffect(() => {
    if (receiver) {
      if (receiver.type === "group") {
        joinGroup(receiver._id);
      } else if (receiver.type === "user") {
        joinChatRoom(receiver._id);
      }
    }
  }, [receiver]);

  useEffect(() => {
    fetchUserList();
  }, [onlineUsers]);

  const onModelClose = () => {
    setOpen(false);
    fetchUserList();
  };

  return (
    <CommonLayout title={"Chat"}>
      <div>
        {loadUser ? (
          <ChatUserLoader />
        ) : (
          <div>
            {users.length !== 0 ? (
              <div className="flex shadow-[rgba(100,100,111,0.2)0px_7px_29px_0px] h-[calc(87vh-54px)]">
                <div
                  className={`md:block ${
                    isChatWindowVisible ? "hidden" : "block"
                  } w-full md:w-[300px] bg-white pb-2 h-[calc(87vh-54px)] overflow-y-auto border-r border-gray-200`}
                >
                  <div className="flex justify-end">
                    {userData?.role === "teacher" && (
                      <button
                        className="flex items-center justify-center m gap-1 w-[132px] h-[43px] bg-lightblue mb-2 border-primary rounded-md my-4 mr-2"
                        onClick={() => setOpen(true)}
                      >
                        <BiPlus className="text-primary sm:w-[22px] w-[16px] sm:h-[22px] h-[16px]" />
                        <p className="sm:text-[16px] text-[14px] text-primary font-medium mb-0">
                          Create Group
                        </p>
                      </button>
                    )}
                  </div>
                  <div>
                    {users?.map((user) => (
                      <div
                        className="p-[10px_18px] cursor-pointer hover:bg-lightblue border-b"
                        key={user._id}
                        onClick={() => handleUserClick(user)}
                      >
                        <div className="flex items-start justify-between gap-2">
                          <div className="flex items-center justify-between gap-[12px] w-full">
                            <div className="relative">
                              <img
                                src={
                                  user.profileImageUrl
                                    ? user.profileImageUrl
                                    : user.groupImage || "defaultImage.jpg"
                                }
                                alt={
                                  user.firstName && user.lastName
                                    ? `${user.firstName} ${user.lastName}`
                                    : user.groupName || "Default Image"
                                }
                                className="min-w-[40px] w-[40px] h-[40px] min-h-[40px] rounded-full object-cover"
                                // onError={(e) => (e.target.src = defaultImage)}
                              />
                              {isUserOnline(user._id)?.status === true ||
                              user.isOnline ? (
                                <div className="bottom-[-2px] right-0 w-3 h-3 bg-green-500 border border-white rounded-full absolute"></div>
                              ) : null}
                            </div>
                            <div className="flex justify-between items-center w-full">
                              <div className="flex items-start flex-col">
                                <h1 className="text-[16px] text-black font-medium mb-0">
                                  {user.firstName && user.lastName
                                    ? `${user.firstName} ${user.lastName}`
                                    : user.groupName}
                                </h1>
                                <p className="text-[14px] text-gray1 font-normal mb-0 w-full max-w-[200px] truncate">
                                  {user.type === "user"
                                    ? unreadMessages[
                                        `lastMessage_${user._id}`
                                      ] || user.lastMessage
                                    : groupLastMessage[user._id] ||
                                      user.lastMessage}
                                </p>
                              </div>

                              {user.type === "user" &&
                                unreadMessages[user._id] > 0 && (
                                  <div className="w-4 h-4 bg-primary rounded-full text-white text-[10px] font-medium flex justify-center items-center">
                                    {unreadMessages[user._id]}
                                  </div>
                                )}
                              {user.type === "group" &&
                                unreadGroupMessages[user._id] &&
                                unreadGroupMessages[user._id][userData._id] >
                                  0 && (
                                  <div className="w-4 h-4 bg-primary rounded-full text-white text-[10px] font-medium flex justify-center items-center">
                                    {
                                      unreadGroupMessages[user._id][
                                        userData._id
                                      ]
                                    }
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  className={`flex-1 bg-white flex-col relative ${
                    isChatWindowVisible ? "block" : "hidden"
                  } md:block`}
                >
                  {receiver && (
                    <div>
                      {/* Receiver Info */}
                      <div className="p-[11px_14px] flex items-center gap-1 border-b border-b-[#ACACAC]">
                        <button onClick={() => setIsChatWindowVisible(false)}>
                          <MdKeyboardArrowLeft className="text-4xl lg:hidden text-primary" />
                        </button>
                        <div className="flex gap-[12px]">
                          <img
                            src={
                              receiver.profileImageUrl
                                ? receiver.profileImageUrl
                                : receiver.groupImage
                            }
                            alt=""
                            className="w-[40px] h-[40px] rounded-full object-cover"
                            // onError={(e) => (e.target.src = defaultImage)}
                          />
                          <div className="flex items-start flex-col">
                            <h1 className="sm:text-[18px] text-[16px] text-black font-semibold mb-0">
                              {receiver.firstName && receiver.lastName
                                ? `${receiver.firstName} ${receiver.lastName}`
                                : receiver.groupName}
                            </h1>
                            <p className="text-[12px] font-normal mb-0">
                              {receiver.isOnline ? (
                                <p className="text-green-600">Active Now</p>
                              ) : receiver.type === "user" ? (
                                <p>
                                  {formattedLastActive(receiver.lastActive)}
                                </p>
                              ) : null}
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* Chat Messages */}
                      <div
                        ref={containerRef}
                        onScroll={handleScroll}
                        className="h-[calc(76.50vh-100px)] overflow-y-auto my-scroll"
                      >
                        {loadChat ? (
                          <div>
                            <ChatLodar />
                          </div>
                        ) : (
                          <div
                            className={`flex-grow p-3  flex flex-col ${
                              chatHistory.length === 0 &&
                              "justify-center items-center"
                            } `}
                          >
                            {isLoading && (
                              <div className="flex justify-center items-center my-3">
                                <MessageLoder />
                              </div>
                            )}
                            <div>
                              {filteredMessages.map((msg, index) => (
                                <div
                                  key={index}
                                  className={`flex ${
                                    msg.sender === userData._id
                                      ? "justify-end"
                                      : "justify-start"
                                  }`}
                                >
                                  {/* Sender message */}
                                  {msg.sender === userData._id ? (
                                    <div className="sm:my-2 my-[10px] flex items-end flex-col">
                                      <h3 className="text-[10px] text-gray1 font-normal">
                                        {dayjs(msg.createdAt).format("h:mm A")}
                                      </h3>
                                      <div className="break-words w-full md:max-w-[600px] sm:max-w-[470px] max-w-[170px] bg-primary sm:text-[14px] text-[12px] text-white font-normal p-[10px_18px] rounded-lg">
                                        {msg.message}
                                      </div>

                                      {/* Message status indicator */}
                                      {msg.status === "delivered" && (
                                        <div className="text-[10px] text-gray-600">
                                          Delivered
                                        </div>
                                      )}
                                      {msg.status === "read" && (
                                        <div className="text-[10px] text-gray-600">
                                          Read
                                        </div>
                                      )}
                                      {msg.status === "sent" && (
                                        <div className="text-[10px] text-gray-600">
                                          Sent
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    // Receiver message
                                    <div className="sm:my-2 my-[10px] flex items-start flex-col">
                                      <h3 className="text-[10px] text-gray1 font-normal">
                                        {dayjs(msg.createdAt).format("h:mm A")}
                                      </h3>
                                      <div className="break-words w-full 2xl:max-w-[680px] xl:max-w-[500px] lg:max-w-[400px] md:max-w-[300px] sm:max-w-[400px] max-w-[200px] bg-lightblue p-[10px_18px] rounded-lg sm:text-[14px] text-[12px] text-nevyblue font-normal">
                                        {msg.message}
                                      </div>
                                      {receiver.type === "group" && (
                                        <div className="text-[10px] text-gray-600">
                                          {" "}
                                          {msg.groupId && msg.sender && (
                                            <span className="text-[10px] text-gray-600 mt-1">
                                              {msg.senderFirstName}{" "}
                                              {msg.senderLastName}
                                            </span>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>

                            {filteredMessages.length === 0 && !loadChat && (
                              <div className="flex justify-center h-[50vh] items-center text-center">
                                <div className="flex items-center justify-center  flex-col">
                                  <PiChatsBold className="text-5xl text-[#8c8b8b]" />
                                  <div className="text-[#8c8b8b]">
                                    You have no Conversation yet.
                                  </div>
                                </div>
                              </div>
                            )}

                            {typingUser &&
                              typingUser.senderId === receiver._id && (
                                <div className="sm:my-[15px] my-[10px] flex items-start flex-col">
                                  <div className="bg-lightblue p-[8px] sm:text-[14px] text-[12px] text-nevyblue font-normal">
                                    Typing...
                                  </div>
                                </div>
                              )}
                          </div>
                        )}
                        {groupTypingUser && receiver.type === "group" && (
                          <div>
                            <div className="sm:my-[15px] pl-3 my-[10px] flex items-start flex-col">
                              <div className="bg-lightblue p-[8px] sm:text-[14px] text-[12px] text-nevyblue font-normal">
                                {`${groupTypingUser.firstName} ${groupTypingUser.lastName} is typing...`}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="border-t border-t-[#ACACAC] p-4 bg-white absolute bottom-0 right-0 left-0">
                        <div className="flex items-end justify-between gap-2">
                          <textarea
                            value={message}
                            onChange={handleTyping}
                            onKeyDown={handleKeyDown}
                            onInput={handleInputResize}
                            className="w-full sm:text-[14px] text-[12px] h-[45px] font-medium text-black placeholder:text-secondary bg-lightblue rounded-lg p-[12px_18px] outline-none resize-none"
                            placeholder="Type your message"
                          />
                          <button
                            onClick={handleSendMessage}
                            className="sm:text-[14px] text-[12px] text-white font-normal sm:w-[120px] w-[100px] sm:h-[45px] h-[32px] bg-primary flex items-center justify-center gap-4 rounded-lg"
                          >
                            Send <IoSend />
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="bg-white shadow-[rgba(100,100,111,0.2)0px_7px_29px_0px] h-[calc(100vh-170px)] flex justify-center items-center">
                <p>No Users Found</p>
              </div>
            )}
          </div>
        )}
      </div>
      <CreateGroupModal open={open} setOpen={setOpen} onClose={onModelClose} />
    </CommonLayout>
  );
}

export default Chat;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { handleApiRequest } from "../utils/handleApiRequest";

export const fetchMySchedules = createAsyncThunk('schedules/fetchMySchedules', async () => {
    const data = await handleApiRequest('GET', '/my-schedules');
    return data;
});

const scheduleSlice = createSlice({
    name: 'schedules',
    initialState: {
        schedules: [],
        loading: false,
        error: null,
        status: "idle"
    },
    reducers: {
        resetState: () => ({
            schedules: [],
            loading: false,
            error: null,
            status: "idle",
        }),
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMySchedules.pending, (state) => {
                state.loading = true;
                state.status = "loading";
            })
            .addCase(fetchMySchedules.fulfilled, (state, action) => {
                state.loading = false;
                state.schedules = action.payload;
                state.status = "success";
            })
            .addCase(fetchMySchedules.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                state.status = "error";
            });
    },
});

export const { resetState: resetScheduleState } = scheduleSlice.actions;

export default scheduleSlice.reducer;

import { handleApiRequest } from "../../utils/handleApiRequest";

export const sendChat = (formData) =>
    handleApiRequest("POST", "/send-message", formData);

export const getChatUserList = () => handleApiRequest("GET", "/chat-users");

export const markAsRead = (messageId) =>
    handleApiRequest("PUT", `/read/${messageId}`);

export const getChatHistory = (receiver, page) => {
    const url =
        receiver.type === "group"
            ? `/chat-history?groupId=${receiver._id}&page=${page}`
            : `/chat-history?receiverId=${receiver._id}&page=${page}`;

    return handleApiRequest("GET", url);
};

export const createGroup = (formData) =>
    handleApiRequest("POST", "/create-group", formData);

export const checkStatus = (status) =>
    handleApiRequest("PUT", "/check-status", { isOnline: status });
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOnline: "",
    contactNumber: "",
    role: "",
    firstName: '',
    lastName: '',
    email: "",
    source: '',
    // subscription: '',
    availability: '',
    startTime: '',
    endTime: '',
    duration: '',
    // couponCode: '',
    skills: [],
    profilePicture: {},
    skillMatrix: [],
    profileImageUrl: "",
    isProfileSet: false,
};

const teacherProfileSlice = createSlice({
    name: "teacherProfile",
    initialState,
    reducers: {
        updateTeacherProfile: (state, action) => {
            return { ...state, ...action.payload, isProfileSet: true, };
        },
        updateTeacherSkillMatrix: (state, action) => {
            state.skillMatrix = action.payload;
        },
        clearTeacherProfile: () => initialState,
    },
});

export const { updateTeacherProfile, updateTeacherSkillMatrix, clearTeacherProfile } =
    teacherProfileSlice.actions;
export default teacherProfileSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchClasses = createAsyncThunk(
    'classes/fetchClasses',
    async ({ skillLevel = [], subcategory = [], customFilters = '', page = 1, limit = 10 }, { rejectWithValue }) => {
        try {
            const params = {
                ...(skillLevel.length > 0 && { skillLevel: skillLevel.join(',') }),
                ...(subcategory.length > 0 && { subcategory: subcategory.join(',') }),
                ...(customFilters && { customFilters }),
                page,
                limit,
            };
            const response = await axios.get(process.env.REACT_APP_BACKEND_URL + '/api/get-all-class', { params });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const classesSlice = createSlice({
    name: 'classes',
    initialState: {
        classes: [],
        totalCount: 0,
        totalPages: 0,
        currentPage: 1,
        status: 'idle',
        error: null,
        loading: false
    },
    reducers: {
        resetClasses: (state) => {
            state.classes = [];
            state.totalCount = 0;
            state.totalPages = 0;
            state.currentPage = 1;
            state.status = 'idle';
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchClasses.pending, (state) => {
                state.status = 'loading';
                state.loading = true
            })
            .addCase(fetchClasses.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.loading = false;

                if (action.payload.classes.length === 0) {
                    state.classes = [];
                    state.totalCount = 0;
                    state.totalPages = 0;
                } else {
                    state.classes = action.payload.classes;
                    state.totalCount = action.payload.totalCount;
                    state.totalPages = action.payload.totalPages;
                }

                state.currentPage = action.payload.currentPage;
            })

            .addCase(fetchClasses.rejected, (state, action) => {
                state.status = 'failed';
                state.loading = false
                state.error = action.payload || 'An error occurred';
            });
    }
});

export const { resetClasses } = classesSlice.actions;
export default classesSlice.reducer;
import { MdOutlineMessage, MdOutlineSpaceDashboard } from "react-icons/md";
import { FaChalkboardUser, FaRegCalendarCheck } from "react-icons/fa6";
import { CgAddR } from "react-icons/cg";
import { SlLayers } from "react-icons/sl";
import { HiOutlineCreditCard } from "react-icons/hi2";
import { AiFillStar, AiOutlinePayCircle } from "react-icons/ai";
import { IoSettingsOutline } from "react-icons/io5";
import { FiUsers } from "react-icons/fi";
import { PiVideoCameraBold } from "react-icons/pi";

export const menus = [
    {
        label: "Dashboard",
        icon: MdOutlineSpaceDashboard,
        link: (role) => `/${role}/dashboard`,
        roleRestriction: null,
    },
    {
        label: "My Schedule",
        icon: FaRegCalendarCheck,
        link: () => "/schedule",
        roleRestriction: null,
    },
    // {
    //     label: "Create New Class",
    //     icon: CgAddR,
    //     link: () => "/create-classes",
    //     roleRestriction: (role) => role !== "student",    
    //     // disabledCondition: (isSetProfile) => !isSetProfile,
    // },
    {
        label: "Create New Class",
        icon: CgAddR,
        link: () => "/create-classes",
        roleRestriction: (role) => role !== "student",
        disabledCondition: ({ isSetProfile, isCredible } = {}) => {
            // If both are true, disableCondition should be false
            if (isSetProfile && isCredible) {
                return false;
            }
            // If one is true and the other is false, disableCondition should be false
            else if (isSetProfile || isCredible) {
                return false;
            }
            // If both are false, disableCondition should be true
            else {
                return true;
            }
        }
    },
    {
        label: "My Classes",
        icon: SlLayers,
        link: () => "/my-classes",
        roleRestriction: null,
    },
    {
        label: "All Classes",
        icon: FaChalkboardUser,
        link: () => "/course",
        roleRestriction: (role) => role === "student",
    },
    {
        label: "Meetings",
        icon: PiVideoCameraBold,
        link: () => "/meetings",
    },
    {
        label: "Find Teacher / My Students",
        icon: FiUsers,
        link: (role) => (role === "student" ? "/teacher/list" : "/student/list"),
        roleRestriction: null,
    },
    // {
    //     label: "My Atdendes",
    //     icon: FiUsers,
    //     link: (role) => (role === "teacher" ? "/teacher/attendes" : "#"),
    //     roleRestriction: null,
    // },
    {
        label: "Subscription",
        icon: HiOutlineCreditCard,
        // link: () => "#",
        link: (role) => (role === "teacher" ? "/teacher/financials" : "#"),
        roleRestriction: null,
    },
    {
        label: "Message",
        icon: MdOutlineMessage,
        link: () => "/chat",
        roleRestriction: null,
    },
    {
        label: "Payment",
        icon: AiOutlinePayCircle,
        link: () => "/teacher/payment",
        // link: (role) => (role === "student" ? "/student/subscription" : "/teacher/subsciption"),
        roleRestriction: (role) => role === "teacher",
    },
    {
        label: "Settings",
        icon: IoSettingsOutline,
        link: (role) => `/${role}/profile`,
        roleRestriction: null,
    },
];

export const Subscription = [
    {
        id: 1,
        name: "Silver",
        value: "silver",
    },
    {
        id: 2,
        name: "Gold",
        value: "gold",
    },
    {
        id: 3,
        name: "Platinum",
        value: "platinum",
    },

    // {
    //     id: 4,
    //     name: "Free Trial",
    //     value: "freetrial"
    // }
];

export const Plan = [
    {
        id: 1,
        name: "Monthly",
        value: "monthly"
    },
    {
        id: 2,
        name: "Yearly",
        value: "yearly"
    }
]

export const Availability = [
    {
        id: 1,
        name: "UTC (Coordinated Universal Time)",
        value: "UTC",
    },
    {
        id: 2,
        name: "IST (Indian Standard Time, UTC+5:30)",
        value: "IST",
    },
    {
        id: 3,
        name: "EST (Eastern Standard Time, UTC-5)",
        value: "EST",
    },
    {
        id: 4,
        name: "PST (Pacific Standard Time, UTC-8)",
        value: "PST",
    },
    {
        id: 5,
        name: "GMT (Greenwich Mean Time, UTC+0)",
        value: "GMT",
    },
    {
        id: 6,
        name: "JST (Japan Standard Time, UTC+9)",
        value: "JST",
    },
    {
        id: 7,
        name: "AEST (Australian Eastern Standard Time, UTC+10)",
        value: "AEST",
    },
    {
        id: 8,
        name: "CST (Central Standard Time, UTC-6)",
        value: "CST",
    },
    {
        id: 9,
        name: "MST (Mountain Standard Time, UTC-7)",
        value: "MST",
    },
    {
        id: 10,
        name: "CET (Central European Time, UTC+1)",
        value: "CET",
    },
    {
        id: 11,
        name: "BST (British Summer Time, UTC+1)",
        value: "BST",
    },
    {
        id: 12,
        name: "SGT (Singapore Time, UTC+8)",
        value: "SGT",
    },
    {
        id: 13,
        name: "CST (China Standard Time, UTC+8)",
        value: "CST",
    },
    {
        id: 14,
        name: "NZST (New Zealand Standard Time, UTC+12)",
        value: "NZST",
    },
    {
        id: 15,
        name: "BRT (Brasilia Time, UTC-3)",
        value: "BRT",
    },
    {
        id: 16,
        name: "SAST (South African Standard Time, UTC+2)",
        value: "SAST",
    },
    {
        id: 17,
        name: "MSK (Moscow Standard Time, UTC+3)",
        value: "MSK",
    },
    {
        id: 18,
        name: "KST (Korean Standard Time, UTC+9)",
        value: "KST",
    },
    {
        id: 19,
        name: "GST (Gulf Standard Time, UTC+4)",
        value: "GST",
    },
    {
        id: 20,
        name: "PHT (Philippine Time, UTC+8)",
        value: "PHT",
    },
];

export const TeachersTab = ["Personal Settings", "Skill Matrix", "Fee Structure", "Setting"];
export const StudentTab = ["Personal Settings", "Financials", "Transaction History", "Setting"];

export const rating = [
    { star: <AiFillStar />, name: "5 Star", value: 5 },
    { star: <AiFillStar />, name: "4 Star & up", value: 4 },
    { star: <AiFillStar />, name: "3 Star & up", value: 3 },
    { star: <AiFillStar />, name: "2 Star & up", value: 2 },
    { star: <AiFillStar />, name: "1 Star & up", value: 1 },
];

export const Coupon = [
    {
        id: 1,
        name: "Yes",
        value: true,
    },
    {
        id: 2,
        name: "No",
        value: false,
    },
];

export const Source = [
    { id: 1, name: "Google", value: "google" },
    { id: 2, name: "Friend or Family", value: "friend_or_family" },
    { id: 3, name: "Blog", value: "blog" },
    { id: 4, name: "Advertisement", value: "advertisement" },
    { id: 5, name: "Referral", value: "referral" },
    { id: 6, name: "Other", value: "other" },
];

export const SkillLevel = [
    {
        id: 1,
        name: "Beginner",
        value: "beginner",
    },
    {
        id: 2,
        name: "Intermediate",
        value: "intermediate",
    },
    {
        id: 3,
        name: "Advanced",
        value: "advanced",
    },
];

export const Role = [
    {
        id: 1,
        name: "Teacher",
        value: "teacher",
    },
    {
        id: 2,
        name: "Student",
        value: "student",
    },
];

export const Interest = [
    { id: 1, name: "Music", value: "music" },
    { id: 2, name: "Dancing", value: "dancing" },
    { id: 3, name: "Singing", value: "singing" },
    { id: 4, name: "Painting", value: "painting" },
    { id: 5, name: "Drawing", value: "drawing" },
    { id: 6, name: "Photography", value: "photography" },
    { id: 7, name: "Sports", value: "sports" },
    { id: 8, name: "Cooking", value: "cooking" },
    { id: 10, name: "Reading", value: "reading" },
    { id: 11, name: "Writing", value: "writing" },
    { id: 12, name: "Technology", value: "technology" },
    { id: 13, name: "Gardening", value: "gardening" },
    { id: 14, name: "Crafting", value: "crafting" },
    { id: 15, name: "Fitness", value: "fitness" },
    { id: 16, name: "Volunteering", value: "volunteering" },
    { id: 17, name: "Other", value: "other" },
];

export const Session = [
    {
        id: 1,
        name: "Regular class",
        value: "regular class",
    },
    {
        id: 2,
        name: "Makeup Class",
        value: "makeup class",
    },
];

export const ClassType = [
    {
        id: 1,
        name: "Individual",
        value: "individual",
    },
    {
        id: 2,
        name: "Group",
        value: "group",
    },
];

export const frequency = [
    {
        id: 1,
        name: "Daily",
        value: "daily",
    },
    {
        id: 2,
        name: "Custom Days",
        value: "custom days",
    },
];

export const daysOfWeek = [
    {
        id: 1,
        name: "Sunday",
        value: "sunday",
    },
    {
        id: 2,
        name: "Monday",
        value: "monday",
    },
    {
        id: 3,
        name: "Tuesday",
        value: "tuesday",
    },
    {
        id: 4,
        name: "Wednesday",
        value: "wednesday",
    },
    {
        id: 5,
        name: "Thursday",
        value: "thursday",
    },
    {
        id: 6,
        name: "Friday",
        value: "friday",
    },
    {
        id: 7,
        name: "Saturday",
        value: "saturday",
    },
];

export const Currencies = [
    {
        id: 1,
        symbol: "$",
        code: "USD",
        name: "US Dollar",
        value: "us_doller",
    },
    {
        id: 2,
        symbol: "€",
        code: "EUR",
        name: "Euro",
        value: "euro",
    },
    {
        id: 3,
        symbol: "£",
        code: "GBP",
        name: "British Pound",
        value: "british_pound",
    },
    {
        id: 4,
        symbol: "₹",
        code: "INR",
        name: "Indian Rupee",
        value: "indian_rupee",
    },
    {
        id: 5,
        symbol: "¥",
        code: "JPY",
        name: "Japanese Yen",
        value: "japanese_yen",
    },
];
import React, { useEffect, useRef, useState } from "react";
import LOGOMOBILE from "../../assets/Images/LogoMobile.png";
import { PiSignOutBold } from "react-icons/pi";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PERSONIMG from "../../assets/Images/person.png";
import {
  clearStudentProfile,
  updateStudentProfile,
} from "../../redux/studentProfileSlice";
import { persistStore } from "redux-persist";
import store from "../../redux/store";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { getProfile, switchUserRole } from "../../services/apis/authentication";
import {
  clearTeacherProfile,
  updateTeacherProfile,
} from "../../redux/teacherProfileSlice";
import { FaAngleRight, FaCheck, FaRegUser } from "react-icons/fa6";
import ConfirmationModel from "../../model/ConfirmationModel";
import { useModal } from "../../contexts/ModelContext";
import {
  allNotifications,
  getPendingData,
} from "../../services/apis/connectionApis";
import { clearCategories } from "../../redux/categorySlice";
import { resetScheduleState } from "../../redux/scheduleSlice";
import { resetTeacherState } from "../../redux/teacherListSlice";
import { resetClasses } from "../../redux/classListSlice";
import { resetFilters } from "../../redux/filterSlice";
import { getUserRole } from "../../pages/router/ProtectedRoute";
import NotificationDropdown from "../../model/NotificationDropdown";
import ConnectionModel from "../../model/ConnectionModel";
import { menus } from "../../constant/dataConstant";
import AVATAR from "../../assets/Images/user.png";
import { FaSync } from "react-icons/fa";
import { fetchStudentList } from "../../redux/studentListSlice";
import useSocket from "../../hooks/useSocket";
import { useOnlineUsers } from "../../contexts/OnlineUserContext";

function UserHeader() {
  const { onlineUsers, setOnlineUsers } = useOnlineUsers();
  const { socket } = useSocket();
  const [preview, setPreview] = useState(PERSONIMG);
  const [userName, setUserName] = useState("");
  const [switchRole, setSwitchRole] = useState(false);
  const role = getUserRole();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [updatedRole, setUpdateRole] = useState("");
  const [openModel, setOpenModel] = useState(false);
  const { showModal } = useModal();
  const studentProfile = useSelector((state) => state.studentProfile);
  const teacherProfile = useSelector((state) => state.teacherProfile);
  const [notifications, setNotifications] = useState([]);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const userData = role === "student" ? studentProfile : teacherProfile;

  // Close the dropdown when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchRequests = async () => {
    try {
      const response = await getPendingData();
      if (response.success) {
        setPendingRequests(response.data);
      }
    } catch (error) {
      console.error("Error fetching pending requests:", error);
    }
  };

  useEffect(() => {
    fetchNotifications();
    fetchRequests();
  }, []);

  const getProfileFormData = async () => {
    try {
      const data = await getProfile();
      if (data && Object.keys(data).length > 0) {
        const profileData = data.user;
        setSwitchRole(profileData.switchRole);
        setUserName(profileData.firstName + " " + profileData.lastName);
        if (profileData.profileImageUrl) {
          setPreview(profileData.profileImageUrl);
        }
        if (role === "student") {
          dispatch(updateStudentProfile(profileData));
        } else if (role === "teacher") {
          dispatch(updateTeacherProfile(profileData));
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirmClick = async () => {
    const response = await switchUserRole({ newRole: updatedRole });
    if (response) {
      showModal(response);
      if (response.success) {
        if (role === "student") {
          dispatch(clearStudentProfile());
        } else {
          dispatch(clearTeacherProfile());
        }
        localStorage.setItem("authToken", response.token);
        localStorage.setItem("role", response.role);

        navigate(`/${response.role}/dashboard`);
      }
    }
    setOpenModel(false);
  };

  // Handle user status updates
  useEffect(() => {
    if (socket) {
      socket.emit("user_online", userData._id);

      const handleConnect = () => {
        if (userData) {
          socket.emit("join", userData._id);
        }
      };

      const handleNewConnectionRequest = () => {
        fetchNotifications();
        fetchRequests();
      };

      const handleConnectionRequestResponse = () => {
        fetchNotifications();
        fetchRequests();
        if (role === "teacher") {
          dispatch(fetchStudentList({ page: 1, limit: 12 }));
        }
      };

      const handleDisconnect = () => {
        console.log("Socket disconnected");
      };

      const handleUserStatusChange = ({ userId, status }) => {
        setOnlineUsers((prevUsers) => {
          const updatedUsers = new Map(prevUsers);
          if (status === "online") {
            updatedUsers.set(userId, true);
          } else {
            updatedUsers.delete(userId);
          }
          return updatedUsers;
        });
      };

      socket.on("connect", handleConnect);
      socket.on("userStatusChange", handleUserStatusChange);
      socket.on("newConnectionRequest", handleNewConnectionRequest);
      socket.on("connectionRequestResponse", handleConnectionRequestResponse);
      socket.on("disconnect", handleDisconnect);

      // Cleanup listener on unmount
      return () => {
        socket.off("userStatusChange", handleUserStatusChange);
        socket.off("connect", handleConnect);
        socket.off("newConnectionRequest", handleNewConnectionRequest);
        socket.off(
          "connectionRequestResponse",
          handleConnectionRequestResponse
        );
        socket.off("disconnect", handleDisconnect);
      };
    }
  }, [socket, userData, setOnlineUsers, role, dispatch]);

  useEffect(() => {
    if (role === "student" && studentProfile.profileImageUrl) {
      setPreview(studentProfile.profileImageUrl);
    } else if (role === "teacher" && teacherProfile.profileImageUrl) {
      setPreview(teacherProfile.profileImageUrl);
    }
  }, [role, studentProfile, teacherProfile]);

  const handleLogoutUser = () => {
    localStorage.removeItem("authToken");
    if (role === "student") {
      dispatch(clearStudentProfile());
    } else {
      dispatch(clearTeacherProfile());
    }
    dispatch(resetTeacherState());
    dispatch(resetScheduleState());
    dispatch(clearCategories());
    dispatch(resetClasses());
    dispatch(resetFilters());
    const persistor = persistStore(store);
    persistor.purge();
    navigate("/");
  };

  const handleSwitchRole = (updatedRole) => {
    setUpdateRole(updatedRole);
    setOpenModel(true);
  };

  const fetchNotifications = async () => {
    const data = await allNotifications();
    setNotifications(data.notifications);
  };

  useEffect(() => {
    getProfileFormData();
  }, []);

  useEffect(() => {
    let profileData = null;

    if (role === "student" && studentProfile.isProfileSet) {
      profileData = studentProfile;
    } else if (role === "teacher" && teacherProfile.isProfileSet) {
      profileData = teacherProfile;
    }

    if (profileData) {
      setUserName(`${profileData.firstName} ${profileData.lastName}`);
    }
  }, [role, studentProfile, teacherProfile]);

  return (
    <div className="relative z-10">
      <header className=" top-0 left-0 w-full z-10 bg-gray1">
        <Dialog
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
          className="xl:hidden "
        >
          <div className="fixed inset-0 z-10 bg-[#00000038]" />
          <DialogPanel
            className={`transform transition-transform duration-300 ease-in-out flex flex-col justify-between fixed inset-y-0 left-0 z-10 top-0 w-full overflow-y-auto bg-white p-[20px] sm:max-w-[300px] sm:ring-1 sm:ring-gray-900/10 h-full ${
              mobileMenuOpen ? "translate-x-0" : "-translate-x-full"
            }`}
          >
            <div>
              <div className="flex items-center justify-end">
                <button
                  type="button"
                  onClick={() => setMobileMenuOpen(false)}
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon aria-hidden="true" className="size-6" />
                </button>
              </div>
              <div className="pb-[20px]">
                <div>
                  <ul className="text-gray-500 font-semibold flex flex-col gap-2">
                    {menus.map((menu, index) => {
                      const isVisible =
                        !menu.roleRestriction || menu.roleRestriction(role);
                      const isDisabled =
                        menu.disabledCondition &&
                        menu.disabledCondition({
                          profileSetup: userData?.profileSetup,
                          isCredible: userData?.isCredible,
                        });
                      const link = menu.link(role);

                      if (!isVisible) return null;

                      return (
                        <NavLink
                          key={index}
                          to={link || "#"}
                          className={({ isActive }) =>
                            link === "#"
                              ? ""
                              : isActive
                              ? "bg-primary active-link text-white rounded-[8px]"
                              : isDisabled
                              ? "cursor-not-allowed opacity-50"
                              : ""
                          }
                          onClick={(e) => {
                            if (isDisabled) e.preventDefault();
                          }}
                        >
                          <li className="rounded-[8px] p-[17px] group hover:bg-primary">
                            <div className="flex items-center gap-[15px] hover:text-white">
                              <menu.icon className="group-hover:text-white text-[22px]" />
                              <h1 className="mb-0 text-nevyblue text-[16px] font-bold leading-[2%] group-hover:text-white">
                                {menu.label === "Find Teacher / My Students"
                                  ? role === "student"
                                    ? "Find Teacher"
                                    : "My Students"
                                  : menu.label}
                              </h1>
                            </div>
                          </li>
                        </NavLink>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>

            <button onClick={handleLogoutUser}>
              <div className="mt-6 rounded-[8px] p-[17px] group hover:bg-primary">
                <div className="flex items-center gap-[15px] hover:text-white">
                  <PiSignOutBold className="group-hover:text-white text-[22px]" />
                  <h1 className="mb-0 text-nevyblue text-[16px] font-bold leading-[2%] group-hover:text-white">
                    Sign out
                  </h1>
                </div>
              </div>
            </button>
          </DialogPanel>
        </Dialog>
      </header>

      <header className="p-[16px] lg:shadow-[0px_0px_10px_-2px_#0000003f] shadow-[0px_0px_10px_-2px_#00000040] bg-white mb-1 sticky flex items-center gap-[20px] xl:justify-end justify-between">
        <div className="xl:hidden block">
          <img src={LOGOMOBILE} alt="" className="w-[45px] h-[45px]" />
        </div>
        <div className="flex items-center gap-[16px] sm:gap-[20px]">
          {/* <div className="w-full sm:min-w-[340px]">
            <div className="relative  items-center bg-white1 xl:block hidden">
              <BiSearch className="absolute w-5 h-5 top-2.5 left-2.5 text-slate-600" />

              <input
                className="w-full placeholder:text-lightgray text-black text-[16px] font-medium bg-white1 rounded-md pl-10 pr-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 shadow-sm focus:shadow bg-transparent"
                placeholder="Search"
              />
            </div>
          </div> */}
          <div className="relative inline-block ">
            <div
              onClick={() => setIsOpen(true)}
              className="cursor-pointer bg-white1 lg:min-w-[50px] min-w-[42px] w-full lg:min-h-[50px] min-h-[42px] h-full rounded-full flex justify-center items-center"
            >
              <ConnectionModel
                open={isOpen}
                setOpen={setIsOpen}
                pendingRequests={pendingRequests}
                fetchRequests={fetchRequests}
              />
            </div>
          </div>

          <div className="relative inline-block">
            <NotificationDropdown
              notifications={notifications}
              setNotifications={setNotifications}
            />
          </div>

          <div className="relative">
            <Menu as="div" className="relative">
              {({ open, close: closeMainMenu }) => (
                <>
                  <div>
                    <MenuButton className="relative flex rounded-full min-h-[50px] min-w-[50px] w-full h-full my-anchor-element has-tooltip">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">Open user menu</span>
                      <img
                        src={preview}
                        alt="profile-image"
                        onError={(e) => (e.target.src = AVATAR)}
                        className="rounded-full w-[50px] h-[50px]"
                      />
                    </MenuButton>
                  </div>
                  <MenuItems
                    anchor="bottom"
                    className="absolute sm:right-0 right-[-50px] z-50 mt-3 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5"
                  >
                    <MenuItem>
                      {({ active }) => (
                        <Link
                          to={`/${role}/profile`}
                          className={`${
                            active ? "bg-gray-100" : ""
                          } flex items-center gap-3 px-4 py-2 text-sm text-gray-700`}
                          onClick={closeMainMenu}
                        >
                          <FaRegUser className="text-[18px]" />
                          Your Profile
                        </Link>
                      )}
                    </MenuItem>
                    {switchRole && (
                      <MenuItem>
                        {({ active }) => (
                          <Menu as="div" className="relative">
                            {({ open: nestedOpen }) => (
                              <>
                                <MenuButton
                                  className={`${
                                    active ? "bg-gray-100" : ""
                                  } flex items-center justify-between gap-3 px-4 py-2 text-sm text-gray-700 w-full`}
                                >
                                  <FaSync className="text-[18px]" />
                                  Switch Role
                                  <FaAngleRight className="ml-auto" />
                                </MenuButton>
                                <MenuItems
                                  anchor="left"
                                  className="absolute sm:left-[-168px] left-[-110px] top-[-6px] z-50 mt-2 sm:w-[160px] w-[100px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5"
                                >
                                  <MenuItem>
                                    {({ active }) => (
                                      <button
                                        onClick={() => {
                                          handleSwitchRole("student");
                                          closeMainMenu();
                                        }}
                                        className={`${
                                          active ? "bg-gray-100" : ""
                                        } block w-full px-4 py-2 text-left text-sm text-gray-700`}
                                      >
                                        Student
                                        {role === "student" && (
                                          <span className="inline-flex items-center ml-2 rounded-full bg-blue-100 px-1 py-1 text-xs font-medium text-blue-700">
                                            <FaCheck />
                                          </span>
                                        )}
                                      </button>
                                    )}
                                  </MenuItem>
                                  <MenuItem>
                                    {({ active }) => (
                                      <button
                                        onClick={() => {
                                          handleSwitchRole("teacher");
                                          closeMainMenu();
                                        }}
                                        className={`${
                                          active ? "bg-gray-100" : ""
                                        } block w-full px-4 py-2 text-left text-sm text-gray-700`}
                                      >
                                        Teacher
                                        {role === "teacher" && (
                                          <span className="inline-flex items-center ml-2 rounded-full bg-blue-100 px-1 py-1 text-xs font-medium text-blue-700">
                                            <FaCheck />
                                          </span>
                                        )}
                                      </button>
                                    )}
                                  </MenuItem>
                                </MenuItems>
                              </>
                            )}
                          </Menu>
                        )}
                      </MenuItem>
                    )}
                    <MenuItem>
                      {({ active }) => (
                        <button
                          onClick={() => {
                            handleLogoutUser();
                            closeMainMenu();
                          }}
                          className={`${
                            active ? "bg-gray-100" : ""
                          } flex items-center gap-3 px-4 py-2 text-sm text-gray-700`}
                        >
                          <PiSignOutBold className="text-[18px]" />
                          Sign out
                        </button>
                      )}
                    </MenuItem>
                  </MenuItems>
                </>
              )}
            </Menu>

            {/* Tooltip */}
          </div>
          <Tooltip
            anchorSelect=".my-anchor-element"
            className="!z-30"
            place="bottom"
          >
            <p className="text-center capitalize">{userName}</p>
            <p className="text-center capitalize">Role: {role}</p>
          </Tooltip>

          <div className="xl:block hidden">
            <button
              onClick={handleLogoutUser}
              className="bg-white1 lg:min-w-[50px] min-w-[42px] w-full lg:min-h-[50px] min-h-[42px] h-full rounded-full flex justify-center items-center "
            >
              <PiSignOutBold className="w-[25px] h-[25px]" />
            </button>
          </div>
          <div className="block xl:hidden">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="size-6" />
            </button>
          </div>
        </div>
      </header>
      <ConfirmationModel
        open={openModel}
        setOpen={setOpenModel}
        title={`Are you sure you want to switch your role to ${updatedRole}`}
        handleConfirmClick={handleConfirmClick}
      />
    </div>
  );
}

export default UserHeader;
